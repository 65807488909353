/******* screen-small.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* fontface.less 2013-1-16 *******/
/* mulish-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/mulish-v13-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* mulish-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/mulish-v13-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 30px;
  margin-bottom: 30px;
}
.unit > .body,
.unit > form > .body {
  width: 100%;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 15px;
  margin-bottom: 15px;
}
.lead {
  margin-bottom: 0 !important;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #000;
  color: #000;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: bold;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.grow {
  text-transform: none !important;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #000;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 0;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 0;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .table {
    font-size: 2.08604954vw;
  }
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
/******* hide-non-mobile.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100%;
  background-color: transparent;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 80%;
  width: 1628px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--primo .desk {
  width: 1080px;
}
#home {
  float: left;
  width: 148px;
  height: 42px;
  margin: 30px 0;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  width: 100%;
}
.cb-layout1 #head {
  position: absolute;
  left: 10%;
  top: 50%;
  z-index: 2;
  width: 80%;
  transform: translateY(-50%);
}
#view.cb-layout2 #head,
#view.cb-layout4 #head {
  display: none;
}
.cb-layout3 #head {
  margin-top: 90px;
}
.cb-layout5 #head {
  margin-top: 105px;
  margin-bottom: 45px;
}
.cb-mobile-access {
  display: none !important;
}
.unit.cb-mobile-invisible {
  display: block !important;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  color: #000;
  font-family: 'Mulish', helvetica, sans-serif;
  line-height: 1.375;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.06em;
}
.section {
  float: left;
  width: 100%;
}
.section--header {
  background: #F0F0F0;
}
.cb-layout1 .section--header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background: none;
}
.cb-layout1 .section--multimood {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.cb-layout5 .section--primo {
  background: #F0F0F0;
}
.cb-layout1 .section--terzo.section--black {
  background: #000;
  color: #fff;
}
.cb-layout1 .section--terzo.section--black a {
  color: #fff;
}
.cb-layout4 .section--terzo {
  background: #F0F0F0;
}
.cb-layout2 .section--secondo {
  background: #F0F0F0;
}
.section--quarto {
  background: #F0F0F0;
}
.cb-layout4 .section--quarto {
  background: #000;
  color: #fff;
}
.cb-layout4 .section--quarto a {
  color: #fff;
  text-decoration: none;
}
.cb-layout4 .section--quarto a:hover {
  text-decoration: underline;
}
.downlink {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down.svg);
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.cb-layout1 .downlink {
  display: block;
}
.foothome {
  float: left;
  width: 220px;
  padding-top: 84px;
  padding-bottom: 84px;
  padding: 81px 160px 81px 10000px;
  margin-left: -10000px;
  background: #000;
}
@media (max-width: 1440px) {
  .foothome {
    padding-right: 60px;
  }
}
.footlogo {
  float: left;
  width: 100%;
  height: auto;
}
.vcard {
  float: left;
  font-size: 14px;
  line-height: 1.28571429;
  margin: 36px 0 36px 160px;
}
@media (max-width: 1440px) {
  .vcard {
    margin-left: 60px;
  }
}
.vcard .org {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
}
.vcard .adr,
.vcard .street-address,
.vcard .tel {
  display: block;
}
.vcard a {
  text-decoration: none;
}
.vcard a:hover,
.vcard a:focus {
  text-decoration: underline;
}
#services {
  float: left;
  margin: 56px 0 56px 210px;
}
@media (max-width: 1440px) {
  #services {
    margin-left: 60px;
  }
}
#services .meta {
  margin: 8px 0;
  display: block;
  font-size: 14px;
  line-height: 1.28571429;
  text-decoration: none;
  text-transform: uppercase;
}
#services .meta:hover,
#services .meta:focus {
  text-decoration: underline;
}
#cmsbox {
  position: absolute;
  right: 0;
  bottom: 45px;
  z-index: 2;
}
#cmsbox .meta {
  float: left;
  margin-right: 20px;
  font-size: 10px;
  text-transform: uppercase;
  text-decoration: none;
  color: #a0a0a0;
}
#cmsbox .meta:last-child {
  margin-right: 0;
}
#cmsbox .meta:hover,
#cmsbox .meta:focus {
  color: #000;
}
.toplink {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-up.svg);
  cursor: pointer;
}
#over {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  height: 100% !important;
}
#root #disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  z-index: 211;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#root #disp.zoom div.body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: none;
  position: relative;
}
#root #disp.zoom div.body > div {
  width: 100%;
  height: 100%;
}
#root #disp.zoom div.body a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 70px;
}
#root #disp.zoom div.body .cb-zoom-image {
  position: relative;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: auto;
  -o-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  max-height: 90% !important;
  max-width: 90% !important;
  border: 1px solid #fff;
  margin: 0 !important;
}
#root #disp.zoom div.body .cb-zoom-image:hover {
  cursor: none !important;
}
#root #disp.zoom div.head {
  position: static;
  height: 0;
  width: 0;
  overflow: visible;
}
#root #disp.zoom div.head h2 {
  display: none;
}
#root #disp.zoom div.head div.ctrl {
  position: static;
  height: 0;
  width: 0;
}
#root #disp.zoom div.head a.cb-hybrid {
  position: fixed;
  top: 50%;
  width: 70px;
  height: 70px;
  z-index: 10;
  margin-top: -35px;
  background-repeat: no-repeat;
  background-position: 0 0;
}
#root #disp.zoom div.head .prev a.cb-hybrid {
  left: 0;
  background-image: url(/images/arrow-left.svg);
}
#root #disp.zoom div.head .next a.cb-hybrid {
  right: 0;
  background-image: url(/images/arrow-right.svg);
}
#root #disp.zoom div.head a.quit {
  position: fixed;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  background: url(/images/zoom-close.svg) no-repeat 0 0;
  z-index: 10;
}
#root #disp.zoom div.foot {
  display: none;
}
.cb-layout1 #expo {
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}
.cb-layout1 #slides .slide {
  width: 100%;
  height: 100%;
}
.cb-layout1 #slides .slide a {
  display: block;
  width: 100%;
  height: 100%;
}
.cb-layout1 #slides img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  transform: translateY(0);
}
.cb-layout1 #expo div.link {
  display: none;
}
.cb-layout3 #expo {
  float: left;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 30.20833333%;
}
.cb-layout3 #slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
.cb-layout3 #slides .slide {
  width: 100%;
}
.cb-layout3 #slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
.cb-layout3 #expo div.link {
  display: none;
}
h1 {
  text-align: center;
  color: #000;
  font-size: 94px;
  line-height: 1.06382979;
  font-weight: bold;
}
.cb-layout1 h1 {
  color: #fff;
}
h2 {
  font-size: 94px;
  line-height: 1.06382979;
  font-weight: bold;
}
.section--primo .area h2 {
  text-align: center;
}
h3 {
  font-size: 24px;
  line-height: 1.25;
  font-weight: bold;
}
h4 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
}
h5 {
  font-size: 37px;
  line-height: 1.24324324;
  color: #767676;
  font-weight: bold;
}
strong {
  font-weight: bold;
}
.text {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
a {
  color: #000;
  text-decoration: underline;
}
a:hover,
a:focus {
  text-decoration: none;
}
.section--primo .area {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cb-layout5 .section--primo .area {
  margin-top: 0;
}
.section--terzo .area {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px;
}
.section--secondo .area {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.cb-layout2 .section--secondo .area {
  margin-bottom: 0;
}
.section--quarto .area {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-layout4 .section--quarto .area {
  text-align: left;
}
.cb-layout5 .section--quarto .area {
  margin-top: 0;
}
#view .area:empty {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.section--secondo .area div.seam div.part {
  margin: 0;
  width: 100%;
}
.section--secondo .area div.seam div.text {
  background: #000;
  color: #fff;
  font-size: 30px;
  line-height: 1.26666667;
  padding: 17px 5%;
  box-sizing: border-box;
}
.section--secondo .area div.flat {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  text-align: left;
}
.section--secondo .area div.flat + div.flat {
  margin-top: -31px;
}
.section--secondo .area div.flat div.part {
  margin: 22px 0;
  width: 100%;
}
div.form .cb-form-required {
  font-size: 12px;
  text-transform: uppercase;
  color: #777;
}
div.form label.name {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.2;
  padding-bottom: 8px;
}
div.form input.text,
div.form textarea {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #000;
  padding: 0 0 4px 0;
  -webkit-appearance: none;
}
div.form div.foot {
  float: left;
  width: 100%;
  margin: 50px 0 0 0 !important;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
div.form input.submit {
  float: left;
  display: block;
  -webkit-appearance: none;
  color: #000;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 25px 106px 25px 36px;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;
  background-size: 70px 70px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right.svg);
  min-height: 0;
}
div.form input.submit:hover {
  background-color: #000;
  color: #fff;
}
.text-section {
  float: left;
  width: 100%;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
}
.text-section:first-child {
  margin-top: 0;
}
.text-section:last-child {
  margin-bottom: 0;
}
.cb-layout4 .section--quarto .area .text-section + .text-section {
  margin-top: -15px;
}
.list--bullet {
  padding-left: 0;
  list-style-type: none;
}
.list--bullet li {
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 16px;
}
div.load {
  background: none !important;
}
div.link a.open,
div.load a.open,
div.link a.load,
div.load a.load {
  color: #000;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 25px 106px 25px 36px;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;
  background-size: 70px 70px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right.svg);
}
#edit div.link a.open,
#edit div.load a.open,
#edit div.link a.load,
#edit div.load a.load {
  min-width: 30px;
}
div.link a.open:hover,
div.load a.open:hover,
div.link a.load:hover,
div.load a.load:hover {
  background-color: #000;
  color: #fff;
}
div.load a.load {
  background-image: url(/images/arrow-down.svg);
}
.section--terzo div.link a.open {
  background: none;
  color: #000;
  border: 2px solid #000;
  padding: 10px 24px;
  font-size: 12px;
}
.section--terzo div.link a.open:hover {
  background: #000;
  color: #fff;
}
.cb-layout1 .section--terzo div.link a.open {
  color: #fff;
  border-color: #fff;
}
.cb-layout1 .section--terzo div.link a.open:hover {
  background: #fff;
  color: #000;
}
.section--secondo div.flat div.link a.open {
  padding: 0 50px 0 0;
  -webkit-box-shadow: none;
  text-transform: none;
  font-size: 14px;
  line-height: 1.28571429;
  background-size: 30px 12px;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-small-right.svg);
  background-color: transparent;
  transition: background-position 0.15s ease-out;
}
.section--secondo div.flat div.link a.open:hover {
  color: #000;
  text-decoration: underline;
  background-color: transparent;
  background-position: right center;
}
.section--quarto div.pure.slim div.link {
  text-align: left;
}
.section--quarto div.pure.slim div.link a.open {
  padding: 0 50px 0 0;
  -webkit-box-shadow: none;
  text-transform: none;
  font-size: 24px;
  line-height: 1.25;
  background-size: 40px 16px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-small-right.svg);
  background-color: transparent;
}
.section--quarto div.pure.slim div.link a.open:hover {
  color: #000;
  text-decoration: underline;
  background-color: transparent;
}
.cb-layout3 .section--primo div.link a.open,
.cb-layout3 .section--primo div.load a.open,
.cb-layout3 .section--primo div.link a.load,
.cb-layout3 .section--primo div.load a.load {
  background-color: #F0F0F0;
}
.cb-layout3 .section--primo div.link a.open:hover,
.cb-layout3 .section--primo div.load a.open:hover,
.cb-layout3 .section--primo div.link a.load:hover,
.cb-layout3 .section--primo div.load a.load:hover {
  background-color: #000;
  color: #fff;
}
.cb-filter.cats {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-filter.cats ul {
  float: left;
  width: 100%;
  display: block;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-filter.cats ul li {
  float: left;
  display: block;
  padding: 0;
  margin: 15px 10px;
}
.cb-filter.cats ul li > * {
  float: left;
  display: block;
  color: #000;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 25px 36px;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;
}
.cb-filter.cats ul li > a:hover {
  background-color: #000;
  color: #fff;
}
.cb-filter.cats ul li.same > * {
  background-color: #000;
  color: #fff;
}
.cb-album.cb-slideshow .body-mobile {
  display: none !important;
}
.text-section {
  float: left;
  width: 100%;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #000;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.375em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'Mulish', helvetica, sans-serif;
  font-size: 16px;
  font-size: 1.6pxrem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.375em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 16px;
  font-size: 1.6pxrem;
  color: #444;
  font-weight: normal;
  font-family: 'Mulish', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  display: inline-block;
  background-color: #000;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #080808;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #000000;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.375em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-top.less 2014-11-04 ***/
.navi {
  display: none;
}
#mobile-navi {
  position: fixed;
  left: 0;
  top: 0;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  z-index: 210;
  height: 100%;
  max-height: 0;
  overflow-y: hidden;
  background: #000;
  transition: all 0.3s ease-in;
}
.naviani {
  float: left;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  transform: translateY(-200px);
  opacity: 0;
  transition: all 0.2s;
}
body.cb-toggle-target-active .naviani {
  transition: all 0.6s 0.1s cubic-bezier(0.08, 0.9, 0.61, 1.03);
  opacity: 1;
  transform: translateY(0);
}
.mobhome {
  float: left;
  width: 148px;
}
.moblogo {
  float: left;
  width: 100%;
  height: auto;
}
.togglenavigation {
  position: fixed;
  top: 37px;
  right: 10%;
  display: block;
  width: 26px;
  height: 20px;
  z-index: 211;
  cursor: pointer;
  color: #000 !important;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #000000 !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 3px;
  width: 26px;
  margin-top: -1.5px;
  background: currentColor;
  border-radius: 10px;
}
.togglenavigation > .tline-1 {
  display: none;
  margin-top: -10px;
}
.togglenavigation > .tline-4 {
  margin-top: 7px;
}
.cb-toggle-target-active .togglenavigation {
  color: #fff !important;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  transform: rotate(45deg);
}
body.cb-toggle-target-active #mobile-navi {
  max-height: 100%;
  overflow-y: scroll;
  transition: all 0.218s ease-out;
}
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
  margin-top: 30px;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
  margin: 6px 0;
}
#mobile-navi .navi .item .menu {
  text-transform: uppercase;
  display: block;
  color: #fff;
  padding: 0 0;
  font-size: 36px;
  line-height: 1;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding: 3px 0;
}
#mobile-navi .navi .item .menu.path {
  background: #fff;
  color: #000;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 37px;
  height: 37px;
  background: #666666;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  transition: all 0.218s;
}
#mobile-navi div.sub2 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
@media (max-width: 1024px) {
  .toplink {
    display: block;
  }
  .section--footer .desk {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .foothome {
    padding-left: 0;
    margin-left: 0;
    width: 100%;
    padding: 25px 0;
    order: 3;
  }
  .footlogo {
    float: none;
    margin: 0 auto;
    width: 148px;
  }
  .vcard {
    order: 1;
    width: 80%;
    margin: 80px 10% 0;
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
  }
  #services {
    order: 2;
    width: 80%;
    margin: 40px 10% 80px;
    text-align: center;
  }
  #services .meta {
    margin: 14px 0;
    font-size: 12px;
    line-height: 1.5;
  }
  #cmsbox {
    width: 100%;
    bottom: 110px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .part {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    font-size: 14px;
    line-height: 1.57142857;
  }
  h1 {
    font-size: 38px;
    line-height: 1.10526316;
  }
  h2 {
    font-size: 38px;
    line-height: 1.10526316;
  }
  h3 {
    font-size: 17px;
    line-height: 1.29411765;
  }
  h5 {
    font-size: 17px;
    line-height: 1.29411765;
  }
}
@media (max-width: 767px) {
  #root #disp.zoom div.body a {
    padding: 0 40px;
  }
  #root #disp.zoom div.body .cb-zoom-image {
    max-height: 85% !important;
    max-width: 85% !important;
  }
  #root #disp.zoom div.head a.cb-hybrid {
    width: 40px;
    height: 40px;
    margin-top: -20px;
  }
  #root #disp.zoom div.head a.quit {
    width: 40px;
    height: 40px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part {
  width: 100%;
}
@media (max-width: 767px) {
  .section--terzo .area {
    text-align: center;
  }
  .section--terzo .area div.unit {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .section--quarto .area div.seam div.part {
    width: 88%;
    margin-left: 6%;
    margin-right: 6%;
  }
  .section--secondo .area div.seam div.text {
    font-size: 15px;
    line-height: 1.2;
    padding: 7px 0;
  }
  .section--secondo .area div.flat {
    padding: 20px 0;
  }
  .section--secondo .area div.flat + div.flat {
    margin-top: -16px;
  }
  .section--secondo .area div.flat div.part {
    margin: 10px 0;
  }
  div.form input.submit {
    font-size: 9px;
    line-height: 1.33333333;
    padding: 11px 45px 11px 11px;
    background-size: 34px 34px;
  }
}
@media (max-width: 767px) {
  div.link a.open,
  div.load a.open,
  div.link a.load,
  div.load a.load {
    font-size: 9px;
    line-height: 1.33333333;
    padding: 11px 45px 11px 11px;
    background-size: 34px 34px;
  }
  .section--secondo div.flat div.link a.open {
    font-size: 12px;
  }
  .cb-filter.cats ul li {
    margin: 5px;
  }
  .cb-filter.cats ul li > * {
    font-size: 9px;
    line-height: 1.33333333;
    padding: 11px;
  }
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/* scan.less 2013-1-16 */
.scan .cb-hybrid {
  text-decoration: none;
}
.swap {
  text-align: center;
}
.scan form,
.scan > table,
.scan fieldset {
  width: 100% !important;
}
.scan > table {
  margin: 0 !important;
}
.swap td > span,
.swap td > .cb-hybrid {
  display: block;
}
td.prev,
td.next {
  color: rgba(0, 0, 0, 0.3);
}
.unit.cb-batch .same {
  background-color: #000000;
  color: #000;
}
.cb-album {
  position: relative;
  padding-top: var(--spacePart);
  padding-bottom: var(--spacePart);
}
.cb-album .body-mobile {
  display: none !important;
}
.cb-album h2 {
  display: none;
}
.cb-album .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cb-album .part {
  width: 100% !important;
  margin: 0 !important;
}
.cb-album .part.desc {
  display: none;
}
#root.ie11 .cb-album img {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-album.cb-album .scan {
  margin-top: 40px !important;
}
.cb-album.cb-album .scan:first-child {
  margin-top: 0 !important;
  margin-bottom: 40px !important;
}
.cb-album .scan table,
.cb-album .scan tbody,
.cb-album .scan tr {
  float: left;
  display: block;
  width: 100%;
}
.cb-matrix .body {
  margin-top: -20px;
  margin-bottom: -20px;
}
.cb-matrix .part {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.cb-matrix .part.foto {
  height: auto !important;
  box-sizing: border-box;
}
.cb-matrix .part.foto a.foto,
.cb-matrix .part.foto span.foto {
  display: block;
  height: 0;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;
}
.cb-matrix .part.foto img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-matrix .part.foto a.foto.zoom img {
  transform: scale(1.1);
}
.cb-matrix.cb-matrix-columns-1 .part.foto {
  width: 100% !important;
}
.cb-matrix.cb-matrix-columns-2 .part.foto {
  width: 30% !important;
}
.cb-matrix.cb-matrix-columns-3 .part.foto {
  width: 6.66666667% !important;
}
.cb-matrix.cb-matrix-columns-4 .part.foto {
  width: -5% !important;
}
.cb-matrix.cb-matrix-columns-5 .part.foto {
  width: -12% !important;
}
.cb-matrix.cb-matrix-columns-6 .part.foto {
  width: -16.66666667% !important;
}
.cb-matrix.cb-matrix-columns-7 .part.foto {
  width: -20% !important;
}
.cb-matrix.cb-matrix-columns-8 .part.foto {
  width: -22.5% !important;
}
.cb-matrix .scan tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.cb-matrix .scan td {
  float: left;
  display: block;
  margin: 0 10px;
  font-size: 16px;
  line-height: 1.25;
}
.cb-matrix .scan td:first-child {
  margin-left: 0;
}
.cb-matrix .scan td:last-child {
  margin-right: 0;
}
.cb-matrix .scan td.prev,
.cb-matrix .scan td.next {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  width: 20px;
  height: 20px;
}
.cb-matrix .scan td.prev a,
.cb-matrix .scan td.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.cb-matrix .scan td.prev:empty,
.cb-matrix .scan td.next:empty {
  opacity: 0.5;
  pointer-events: none;
}
.cb-matrix .scan td.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-matrix .scan td a {
  color: #000;
}
.cb-matrix .scan td .same {
  color: #000;
}
.cb-strips .part.foto {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-strips-images {
  float: left;
  width: 100%;
  height: 0 !important;
  padding-bottom: 56.25%;
  position: relative;
}
.cb-strips-images a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.96);
  z-index: 2;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1), transform 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-images a.is-active {
  z-index: 3;
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
}
.cb-strips-images img {
  opacity: 1 !important;
  display: block !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-strips .scan tbody > tr {
  float: left;
  width: 100%;
  display: flex;
}
.cb-strips .scan tbody > tr > td {
  float: left;
  width: 100%;
  display: block;
}
.cb-strips .scan td.prev,
.cb-strips .scan td.next {
  margin-right: 1.25%;
  width: 6.25%;
}
.cb-strips .scan td.next {
  margin-right: 0;
  margin-left: 1.25%;
}
.cb-strips .scan div.prev,
.cb-strips .scan div.next {
  float: left;
  width: 100%;
  height: 100%;
}
.cb-strips .scan div.prev a,
.cb-strips .scan div.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips .scan div.prev a:hover,
.cb-strips .scan div.next a:hover {
  background-position: 40% 50%;
}
.cb-strips .scan div.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-strips .scan div.next a:hover {
  background-position: 60% 50%;
}
.cb-strips .scan td.cb-strips-thumbs {
  width: 85%;
}
.cb-strips-container {
  float: left;
  width: 100%;
}
.cb-strips-container .body {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cb-strips-container .part.foto {
  position: relative;
}
.cb-strips-container .part.foto a.foto,
.cb-strips-container .part.foto span.foto {
  float: left;
  width: 100%;
  display: block;
  height: 0;
  position: relative;
  padding-bottom: 60%;
  opacity: 0.4;
  transition: opacity 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-container .part.foto a.foto:hover,
.cb-strips-container .part.foto span.foto:hover,
.cb-strips-container .part.foto a.foto.is-active,
.cb-strips-container .part.foto span.foto.is-active {
  opacity: 1;
}
.cb-strips-container .part.foto a.foto .cb-zoom-indicator,
.cb-strips-container .part.foto span.foto .cb-zoom-indicator {
  display: none;
}
.cb-strips-container .part.foto img {
  opacity: 1 !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips-container .part.foto a.foto:hover img {
  transform: scale(1.2);
}
.cb-strips-4 .cb-strips-container .part.foto {
  width: 23.95833333% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  width: 15.50925926% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  width: 11.28472222% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  width: 8.75% !important;
}
.cb-slideshow-images {
  float: left;
  width: 100%;
}
.cb-slideshow .part.foto,
.cb-slideshow .part.crossslideContainer {
  max-height: 1000000000px !important;
  max-width: 100% !important;
  height: 0 !important;
  padding-bottom: 50%;
  overflow: hidden;
  position: relative;
}
.cb-slideshow a.foto {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 1;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-slideshow a.foto.is-activated {
  z-index: 2;
  pointer-events: auto;
  opacity: 1;
}
.cb-slideshow a.foto img.zoom {
  display: none !important;
}
.cb-slideshow img {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
  object-position: center;
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  border: none !important;
  z-index: 1;
}
.cb-slideshow img.is-activated {
  z-index: 2;
}
#view .cb-slideshow.cb-manual img {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-manual a.foto.is-activated img {
  transform: scale(1);
}
#view .cb-slideshow.cb-fading img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-fading img.is-activated,
#view .cb-slideshow.cb-fading .cb-image-figure.is-activated img {
  opacity: 1 !important;
  transform: scale(1);
}
#view .cb-slideshow.cb-sliding img {
  opacity: 0 !important;
  width: 110% !important;
  max-width: 110% !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowSlide;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-sliding img.is-activated,
#view .cb-slideshow.cb-sliding .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowSlide {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -10%;
  }
  100% {
    margin-left: 0;
  }
}
#view .cb-slideshow.cb-moving img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowMove;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-moving img.is-activated,
#view .cb-slideshow.cb-moving .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowMove {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.cb-slideshow .head {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 4;
  height: 0;
  width: 100%;
}
.cb-slideshow .ctrl {
  float: left;
  width: 100%;
}
.cb-slideshow .ctrl a {
  display: block;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 70px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.6);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.cb-slideshow .ctrl a.fade {
  opacity: 0.4;
  cursor: default;
}
@media (max-width: 767px) {
  .cb-slideshow .ctrl a {
    width: 40px;
    height: 40px;
  }
}
.cb-slideshow .ctrl .prev a {
  left: 0;
  background-image: url(/images/arrow-left.svg);
}
.cb-slideshow .ctrl .next a {
  right: 0;
  background-image: url(/images/arrow-right.svg);
}
.crossslide {
  float: left;
  width: 100%;
  max-width: 100% !important;
  position: static !important;
  padding: 0 !important;
}
/******* module-filter.less 2013-1-16 *******/
.cb-count {
  padding: 0 !important;
}
.scan th {
  text-align: center;
  white-space: nowrap;
}
.tabs td,
.time td,
.beta td {
  text-align: center;
}
.mese table {
  table-layout: fixed;
}
.mese td,
.mese tbody th {
  text-align: right;
}
.mese td > .cb-hybrid {
  display: block;
}
.cats span {
  padding: 0;
}
.scan > p {
  font-size: 0;
  text-align: center;
}
.scan > p > span {
  padding: 0;
}
.scan > p > .same,
.scan > p > span > .cb-hybrid {
  padding: 0 0.3em;
}
p > span > .cb-hybrid {
  display: inline-block;
}
p > .same,
p > span > .same {
  display: inline-block;
  background: none !important;
}
/******* module-filter-small.less 2013-1-16 *******/
.cb-mobile-filter {
  font-size: 18px;
  font-size: 1.8rem;
  z-index: 20;
  display: inline-block;
  width: 100%;
  background: #000;
  color: #555;
}
.cb-mobile-filter-link {
  width: inherit;
}
.cb-icon-filter:before {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  font-family: 'cbfont';
  line-height: 1em;
  speak: none;
  padding: 0 0.2em 0 0.6em;
  width: 1.2em;
  content: '\f0c9';
}
.cb-mobile-filter-items {
  padding-bottom: 1em;
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
.cb-mobile-filter-items .cb-hybrid {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  color: #fff;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.4);
}
.cb-mobile-filter-items .cb-hybrid:hover,
.cb-mobile-filter-items .cb-hybrid:focus,
.cb-mobile-filter-items .cb-hybrid:active {
  background: #000000;
  color: #ffffff;
  text-decoration: none;
}
.cb-mobile-filter-items .mese td > span,
.cb-mobile-filter-items li > span {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.cb-mobile-filter-items > .cb-filter {
  margin: 0 !important;
  width: 100% !important;
}
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding-right: 2%;
  padding-left: 2%;
}
.cb-mobile-filter-items li {
  display: inline-block;
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid {
  position: relative;
  overflow: hidden;
  padding-right: 4%;
  padding-left: 4%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 4em;
  height: 100%;
  /*background-color: #1e1e1e;*/
  background-image: -webkit-gradient(linear, left center, right center, from(rgba(30, 30, 30, 0)), to(#1e1e1e));
  background-image: -webkit-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -moz-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -o-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -ms-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  content: '';
}
.mese tbody th,
.mese td > .cb-hybrid,
.mese td > span {
  padding-right: 0.4em;
  padding-left: 0.4em;
}
.cb-mobile-filter-items > .beta {
  text-align: center;
}
.cb-mobile-filter-items > .beta > span {
  float: left;
  padding: 0;
  width: 20%;
  line-height: 3em;
}
.cb-mobile-filter-items > .beta > span > .cb-hybrid {
  padding: 0;
}
.cb-mobile-filter-items > .scan > p > span > .cb-hybrid {
  display: inline-block;
  overflow: hidden;
  max-width: 9em;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.font9 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font12 {
  font-size: 16px;
  font-size: 1.6rem;
}
.font15 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font18 {
  font-size: 25px;
  font-size: 2.5rem;
}
.font21 {
  font-size: 29px;
  font-size: 2.9rem;
}
.font24 {
  font-size: 33px;
  font-size: 3.3rem;
}
.font27 {
  font-size: 37px;
  font-size: 3.7rem;
}
.font30 {
  font-size: 42px;
  font-size: 4.2rem;
}
.font32 {
  font-size: 44px;
  font-size: 4.4rem;
}
.cb-mobile-filter-items .cb-count,
.cb-mobile-filter-items .days td > span,
.cb-mobile-filter-items .week td > span,
.cb-mobile-filter-items .year td > span {
  display: none;
}
.cb-mobile-filter-items .same > span:first-child {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.keys input {
  display: table;
  margin: 0.3em auto;
  width: 86%;
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.keys input + .cb-hybrid {
  display: none;
}
.scan select {
  margin: 0.4em 2%;
  width: 96%;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #000000;
  color: #fff;
  text-decoration: none;
}
/*# sourceMappingURL=./screen-small.css.map */